import M$plugin$MouseSRS from './facade/js/mousesrs';
import M$control$MouseSRSControl from './facade/js/mousesrscontrol';
import M$impl$control$Mouse from './impl/ol/js/extendedMouse';
import M$impl$control$MouseSRSControl from './impl/ol/js/mousesrscontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.MouseSRS = M$plugin$MouseSRS;
window.M.control.MouseSRSControl = M$control$MouseSRSControl;
window.M.impl.control.Mouse = M$impl$control$Mouse;
window.M.impl.control.MouseSRSControl = M$impl$control$MouseSRSControl;
